<template>
  <div class="login-page">
    <div class="login-nav">
      <div class="video">
        <video
          src="/images/admin/video/login.mp4"
          muted
          autoplay
          loop
        />
      </div>

      <div class="login-content">
        <!-- <div class="login-logo">
          <h1>曠世國際有限公司</h1>
          <h2>Kuang Shi</h2>
        </div> -->

        <div class="login-card card-decorate-all">
          <!-- boxall -->
          <div class="login-title">
            後台管理系統登入
          </div>
          <validation-observer ref="loginValidation">
            <b-form @submit.prevent>
              <ul class="login-form">
                <li>
                  <span class="form-icon">
                    <img
                      :src="require('@/assets/images/pages/icons/login-user.png')"
                      alt="帳號"
                    >
                  </span>
                  <validation-provider
                    #default="{ errors }"
                    name="帳號"
                    rules="required"
                  >
                    <b-form-input
                      v-model="login_account"
                      class="form-input"
                      type="text"
                      placeholder="帳號"
                      :state="errors.length > 0 ? false : null"
                      autocomplete="input-account"
                    />
                    <!-- errors.length > 0 ? false : null -->
                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->

                    <span
                      v-if="errors.length > 0"
                      class="form-danger"
                      @click="useSwalToast(false, errors[0])"
                    >
                      <img
                        src="/dashboard/admin/images/common/notifyBlue.png"
                        alt="帳號錯誤"
                      >
                    </span>
                  </validation-provider>
                </li>

                <li>
                  <span class="form-icon">
                    <img
                      :src="require('@/assets/images/pages/icons/login-password.png')"
                      alt="密碼"
                    >
                  </span>
                  <validation-provider
                    #default="{ errors }"
                    name="密碼"
                    rules="required"
                  >
                    <b-form-input
                      v-model="login_password"
                      class="form-input"
                      :type="passwordFieldType"
                      placeholder="密碼"
                      :state="errors.length > 0 ? false : null"
                      autocomplete="input-password"
                    />

                    <span
                      v-if="errors.length > 0"
                      class="form-danger"
                      @click="useSwalToast(false, errors[0])"
                    >
                      <img
                        src="/dashboard/admin/images/common/notifyBlue.png"
                        alt="密碼錯誤"
                      >
                    </span>

                    <span
                      v-else
                      class="form-show-password"
                      @click="togglePasswordVisibility"
                    >
                      <svg
                        viewBox="0 0 100 100"
                        :class="{hide: passwordToggleIcon !== 'EyeOffIcon'}"
                      >
                        <path
                          stroke="#0ba1f8"
                          fill="none"
                          stroke-linecap="round"
                          stroke-width="10"
                          d="M10,50 Q50,0 90,50"
                          class="eye-top"
                        />
                        <path
                          stroke="#0ba1f8"
                          fill="none"
                          stroke-linecap="round"
                          stroke-width="10"
                          d="M10,50 Q50,100 90,50"
                          class="eye-bottom"
                        />
                        <circle
                          fill="none"
                          stroke="#0ba1f8"
                          stroke-width="10"
                          cx="50"
                          cy="50"
                          r="15"
                          stroke-linecap="round"
                          class="eye-ball"
                          transform="rotate(90 50 50)"
                        />
                      </svg>
                    </span>
                  </validation-provider>
                </li>

                <li>
                  <span class="form-icon">
                    <img
                      :src="require('@/assets/images/pages/icons/login-password.png')"
                      alt="驗證碼"
                    >
                  </span>
                  <validation-provider
                    #default="{ errors }"
                    name="驗證碼"
                    rules="required"
                  >
                    <b-form-input
                      v-model="login_captcha"
                      class="form-input"
                      placeholder="驗證碼"
                      :state="errors.length > 0 ? false : null"
                      autocomplete="input-captcha"
                    />

                    <span
                      v-if="errors.length > 0"
                      class="form-danger d-sm-none d-block"
                      @click="useSwalToast(false, errors[0])"
                    >
                      <img
                        src="/dashboard/admin/images/common/notifyBlue.png"
                        alt="驗證碼錯誤"
                      >
                    </span>

                    <span class="form-show-password d-none d-sm-block">
                      <div
                        v-if="!identifyCode"
                        style="height: 50px;"
                        class="d-flex justify-content-center align-items-center"
                      >
                        <b-img
                          src="/dashboard/admin/images/common/loading-2.png"
                          rounded
                          height="30"
                          width="30"
                          alt="loading"
                        />
                      </div>

                      <div class="d-flex justify-content-center">
                        <b-img
                          v-if="identifyCode"
                          :src="identifyCode"
                          class="cursor-pointer"
                          fluid
                          alt="驗證碼"
                          style="max-height: 48px; margin-right: -20px"
                          @click="refreshCode(false)"
                        />
                      </div>
                    </span>
                  </validation-provider>
                </li>

                <li class="p-0">
                  <span class="d-sm-none d-block">
                    <div
                      v-if="!identifyCode"
                      style="height: 50px;"
                      class="d-flex border justify-content-center align-items-center"
                    >
                      <b-img
                        src="/dashboard/admin/images/common/loading-2.png"
                        rounded
                        height="30"
                        width="30"
                        alt="loading"
                      />
                    </div>

                    <div class="d-flex justify-content-center">
                      <b-img
                        v-if="identifyCode"
                        :src="identifyCode"
                        class="cursor-pointer"
                        fluid
                        alt="驗證碼"
                        style="max-height: 80px;"
                        @click="refreshCode(false)"
                      />
                    </div>
                  </span>
                </li>

                <div class="form-action ml-50">
                  <label>
                    <input
                      v-model="rememberMe"
                      type="checkbox"
                      checked
                      class="aui-checkbox"
                    >&nbsp;記住密碼
                  </label>

                  <div
                    v-if="identifyCode !== null"
                  >
                    <span
                      v-if="timeNum > 0"
                      class="text-muted"
                    >
                      {{ timeNum }} 秒後可刷新
                    </span>

                    <b-link
                      v-else
                      class="forget-password"
                      @click="refreshCode(false)"
                    >
                      更換圖片
                    </b-link>
                  </div>
                  <!-- <a
                    href="#"
                    class="forget-password"
                  >忘記密碼？
                  </a> -->
                </div>

                <li>
                  <button
                    type="submit"
                    class="form-btn"
                    @click="validationForm"
                  >
                    登入
                  </button>
                </li>

              </ul>
            </b-form>

            <div class="login-version">
              {{ configVersion }}
            </div>
          </validation-observer>

          <div class="card-decorate-footer" />
        </div>
      </div>
    </div>

    <!-- <div class="copyright">
      COPYRIGHT  © 2012-{{ new Date().getFullYear() }} 曠世國際有限公司
    </div> -->
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BForm, BFormInput, BImg, BLink,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required } from '@validations'
import { useAlert } from '@/libs/mixins/index'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BImg,
    BForm,
    BLink,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      // status: '',
      // login_account: 'super.hannah1022@gmail.com',
      // login_password: '123456789', // Hannah871022
      login_account: '',
      login_password: '',
      passwordFieldType: 'password',
      rememberMe: false,
      required,
      isBusy: false,
      configVersion: '0.0.0',

      login_captcha: '',
      identifyCode: null,
      identifyKey: null,
      codeTimer: null,
      onlineTime: 0,
      timeNum: 0,
      refreshTime: 0,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    this.refreshCode(false)
    this.timer = setInterval(() => {
      this.onlineTime += 1
      if (this.onlineTime === 60) this.refreshCode(true)
    }, 1000)
  },
  created() {
    fetch('/dashboard/admin/config.json')
      .then(response => response.json())
      .then(data => {
        this.configVersion = data.version
      })
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    useSwalToast(state, message) {
      // const positionType = this.$store.state.app.windowWidth >= 1200 ? 'top-start' : 'top-end'
      const Toast = this.$swal.mixin({
        toast: true,
        position: 'top-end',
        padding: '10px 5px',
        width: 300,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        background: '#002d45',
        customClass: {
          title: 'login-swal-title',
          timerProgressBar: 'login-swal-timer-progress-bar',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
      })

      Toast.fire({
        title: `${message}`,
        icon: state ? 'success' : 'error',
      })
    },

    // (重設)
    resetLoginData() {
      this.account = null
      this.password = null
    },

    // (啟動)倒計時器
    startTimer() {
      this.codeTimer = setInterval(() => {
        this.timeNum -= 1
        if (this.timeNum <= 0) {
          clearInterval(this.codeTimer)
          this.codeTimer = null
        }
      }, 1000)
    },

    // (刷新)驗證碼
    refreshCode(skip) {
      if (!skip) {
        if (this.identifyCode === '') return
        if (this.refreshTime >= 3) {
          if (this.timeNum > 0) return
          this.timeNum = 20
          this.startTimer()
        }
      }
      this.identifyCode = ''
      store.dispatch('api/getImgCaptcha')
        .then(response => {
          this.onlineTime = 0
          switch (response.status) {
            case 503: {
              this.useSwalToast(false, '請求太快')
              break
            }
            case 404: {
              this.useSwalToast(false, '驗證圖形請求失敗')
              break
            }
            case 200: {
              const { img, key } = response.data.data
              this.identifyCode = img
              this.identifyKey = key
              this.refreshTime += 1
              break
            }
            default: {
              this.useSwalToast(false, '請求失敗')
              break
            }
          }
        })
    },

    validationForm() {
      if (this.isBusy) return
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          const resolveData = {
            account: this.login_account,
            password: this.login_password,
            captcha: this.login_captcha,
            key: this.identifyKey,
            rememberMe: this.rememberMe,
          }
          this.isBusy = true
          useJwt.login({ ...resolveData })
            .then(response => {
              const { user_data, access_token } = response.data.data
              useJwt.setToken(access_token)
              localStorage.setItem('userData', JSON.stringify(user_data))
              this.$store.commit('app/UPDATE_USERDATA_STATE', user_data)
              this.useSwalToast(true, '成功登入')
              // this.$store.dispatch('app/isSocketFunctionExist', 'setUser')
              //   .then(state => {
              //     if (state) window.socket.setUser()
              //   })
              this.$router.replace({ name: 'admin-home' })
            })
            .catch(error => {
              // this.useHttpCodeAlert(error.response)
              if (error.response && error.response.data) this.useSwalToast(false, error.response.data.message)
              else this.useHttpCodeAlert(error.response)
              this.login_password = null
              this.login_captcha = null
              this.refreshCode(true)
              this.isBusy = false
            })
          // this.useSwalToast(true, '成功登入')
          // this.$router.replace({ name: 'admin-home' })
        } else {
          this.useSwalToast(false, '帳號或密碼不得為空')
        }
      })
    },
  },
  setup() {
    localize('tw')

    const {
      // useSwalToast,
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    return {
      // useSwalToast,
      useAlertToast,
      useHttpCodeAlert,
    }
  },
}
</script>

<style lang="scss">
.login-swal-title {
  color: #9de0f6;
  text-align: left;
}
// .login-swal-timer-progress-bar {
//   // color: red !important;
//   // background: #9de0f6 !important;
// }
.swal2-timer-progress-bar {
  background: #b9b9b92c;
}
.swal2-toast {
  box-shadow: none;
  border: 3px solid #9de0f639;
}
</style>

<style lang="scss" scoped>
.login-page {
  background: #000;
  min-height: 100vh;

  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url('~@/assets/images/background/auth/login-bg.png') no-repeat left center;
    content: "";
    left: 0;
    top: 0;
    z-index: 100;
    pointer-events: none;
  }

  .login-nav {
    // height: 100vh;
    margin: 0 auto;
    z-index: 10;
    box-shadow: 0 0 30px rgba(0,0,0,.1);

    .video {
      position: absolute;
      width: 1200px;
      // left: 80px;
      padding-bottom: 40px;
      pointer-events: none;
      top: 80px;
      // overflow: hidden;
      video {
        width: 100%;
      }
      &::before {
        position: absolute;
        content: "";
        width: 200px;
        height: 100%;
        background: #000;
        right: 0;
        top: 0;
      }
    }

    .login-content {
      width: 85vw;
      min-height: 100vh;
      display: flex;
      justify-content: center; // start
      flex-direction: column;
      align-items: flex-end;

      transform: scale(.7);
      opacity: 0;
      animation: shows 1s forwards;

      .login-logo {
        margin: 12vh 0 20px 0;
        text-align: center;
        color: #fff;
        z-index: 100;
        width: 600px;
        display: flex;
        flex-direction: column;
        align-items: center;
        h1, h2 {
          background: linear-gradient(90deg,#009ef3,#00e9d0);
          background-size: cover;
          -webkit-background-clip: text !important;
          background-clip: text !important;
          -webkit-text-fill-color: transparent;
          text-fill-color: transparent;
        }
        h1 {
          font-weight: bolder;
          font-size: 38px;
          letter-spacing: 2px
        }

        h2 {
          font-size: 28px;
          text-transform: uppercase;
          font-weight: lighter;
          letter-spacing: 2px
        }
      }

      @keyframes shows {
        to {
          transform: scale(1);
          opacity: 1
        }
      }

      .card-decorate-all {
        border: 2px solid #186baf;
        background: rgba(32,163,245,.1);
        position: relative;
        // transform: scale(.7);
        // opacity: 0;
        // animation: shows 1s forwards;
        &::before, &::after {
          position: absolute;
          width: 30px;
          height: 30px;
          content: "";
          border-top: 3px solid #20a3f5;
          top: -2px;
        }
      }

      .card-decorate-footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;

        &::before, &::after {
          position: absolute;
          width: 30px;
          height: 30px;
          content: "";
          border-bottom: 3px solid #20a3f5;
          bottom: -2px
        }
      }

      .card-decorate-all:before,.card-decorate-footer:before {
        border-left: 3px solid #20a3f5;
        left: -2px
        }

      .card-decorate-all:after,.card-decorate-footer:after {
        border-right: 3px solid #20a3f5;
        right: -2px
      }
    }
  }

  .login-card { // loginbox
    // background-color: rgba(0, 0, 0) !important;
    padding: 30px 0 50px 0;
    width: 600px;
    padding: 30px 50px;
    flex-shrink: 0;
    position: relative;
    z-index: 100;
    .login-title {
      color: #00bff4;
      text-align: center;
      position: relative;
      padding: 25px 0;
      font-size: 24px
    }

    .login-form { // logininput
      padding: 0;
      .form-input { // forminput
        border: 2px solid rgba(32,163,245,.4);
        display: block;
        // padding-left: 50px;
        font-size: 18px;
        color: #fff;
        width: 100%;
        line-height: 48px !important;
        outline: 0;
        text-indent: 1px;
        background: rgba(32,163,245,.1);
        border-radius: 5px;
      }

      .form-control {
        // background-color: red;
        padding: 24px 50px;
        // line-height: 48px !important;
      }

      .form-danger {
        position: absolute;
        // height: 50%;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        img {
          width: 30px;
          height: 30px;
          cursor: pointer;
        }
      }

      .form-show-password {
        position: absolute;
        // height: 50%;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
      }

      input::input-placeholder {
        color: #fff
      }

      ::-webkit-input-placeholder {
        color: #fff
      }

      .form-action {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0;
        label {
          color: #fff;
          display: flex;
          font-size: 16px;
          align-items: center;
        }

        .forget-password {
          color: white;
          font-size: 16px;
        }
      }

      .form-btn {
        // background: #20a3f5;
        background: linear-gradient(90deg,#009ef3,#00e9d0);
        display: block;
        width: 100%;
        border: none;
        text-align: center;
        color: #fff;
        line-height: 48PX;
        font-size: 16px;
        border-radius: 5px;
        &:hover {
          color: #fff;
          box-shadow: 0 0 25px #319ee2,  0 0 10px #137bd6;
        }
      }

      .aui-checkbox, input[type=radio] {
        width: 24px;
        height: 24px;
        background: rgba(32,163,245,.1);
        border: solid 2px rgba(32,163,245,.5);
        margin: 0;
        padding: 0;
        position: relative;
        cursor: default;
        -webkit-appearance: none;
        -webkit-user-select: none;
        user-select: none;
        -webkit-transition: background-color ease .1s;
        transition: background-color ease .1s;
        flex-shrink: 0;
        border-radius: 0
      }

      .aui-checkbox,input[type=radio] {
        margin: 0 3px 0 0!important;
        position: relative
      }

      .aui-checkbox:checked,input[type=radio]:checked {
        text-align: center;
        background-clip: padding-box
      }

      .aui-checkbox:checked:after,.aui-checkbox:checked:before,input[type=radio]:checked:after,input[type=radio]:checked:before {
        content: '';
        width: 10px;
        height: 5px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -6px;
        margin-top: -5px;
        background: 0;
        border: 2px solid #439aff;
        border-top: 0;
        border-right: 0;
        z-index: 2;
        -webkit-border-radius: 0;
        border-radius: 0;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg)
      }

      .aui-checkbox:disabled,input[type=radio]:disabled {
        background-color: #f5f5f5;
        border: solid 1px #ddd
      }

      .aui-checkbox:disabled:after,.aui-checkbox:disabled:before,input[type=radio]:disabled:after,input[type=radio]:disabled:before {
        content: '';
        width: .5rem;
        height: .3rem;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -.25rem;
        margin-top: -.4rem;
        background: 0;
        border: 1px solid #fff;
        border-top: 0;
        border-right: 0;
        z-index: 2;
        -webkit-border-radius: 0;
        border-radius: 0;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg)
      }

      input[type="radio"] {
        border-radius: 20px
      }
    }

    .login-form > li {
      display: block;
      padding: 15px 0;
      position: relative;
    }

    .login-form li > .form-icon {
      position: absolute;
      // height: 50%;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center
    }

    .login-version {
      text-align: center;
      margin-top: 20px;
      color: rgba(255, 255, 255, 0.6);
      font-size: 16px;
    }
  }
  .copyright {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 10px 5px;
    background: #000000;
    text-align: center;
    color: rgb(172, 172, 172);
    font-size: 12px;
    z-index: 350;
  }
}
@media (min-width: 1921px) {
  .login-page {
    &::before {
      background-position: center center;
    }
    .video {
      width: 1200px;
      left: 50%;
      transform: translateX(-80%);
    }
    .login-nav {
      justify-content: center;
      .login-content {
        width: 100%;
        padding-top: 5vh;
        align-items: center;
      }
    }
  }
}

@media(max-width: 1200px) {
  .login-page {
    .login-nav {
      justify-content: center;
      .video {
        display: none
      }

      .login-content {
        width: 100%;
        // min-height: 80vh;
        padding: 10vh 0;
        align-items: center;
        .login-logo {
          width: 50%;
          h1 {
            font-size: 30px;
          }

          h2 {
            font-size: 20px;
          }
        }
      }
    }

    .login-card {
      padding: 30px;
      width: 50%;

      .login-form {
        .form-input {
          font-size: 16px;
          color: #fff;
          line-height: 40px;
        }
        .form-btn {
          line-height: 40PX;
          font-size: 16px;
        }
      }
    }
  }
}

@media(max-width: 768px) {
  .login-page {
    .login-nav {
      .video {
        display: none;
      }
      .login-content {
        .login-logo {
          width: 90%;
        }
      }
    }
    .login-card {
      padding: 20px;
      width: 90%;
      .login-title {
        padding: 12px 0;
        font-size: 20px
      }
      .login-form {
        .form-btn {
          line-height: 40PX;
          font-size: 16px;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
svg {
  width: 20px;
  cursor: pointer;
}

.eye-ball {
  stroke-dasharray: 100;
  stroke-dashoffset: 0;
  transition: .3s all ease-in-out;
  transition-delay: .1s;
}

.hide .eye-ball {
  stroke-dashoffset: 100;
  transition-delay: 0;
}

.eye-top {
  transition: .3s all ease-in-out;
}

.hide .eye-top {
  d: path("M10,50 Q50,100 90,50");
  transition-delay: .2s;
}
</style>
